import React, { useEffect } from "react";

export const Header = (props) => {

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('head_logos').style.opacity = 0;
      document.getElementById('menu').style.opacity = 1;
    }, 2000);
    setTimeout(() => {
      document.getElementById('head_logos').style.display = 'none';
      document.getElementById('head_desc').style.opacity = 1
      document.getElementById('hide_opacity').style.opacity = 1;
    }, 3000);
    setTimeout(() => {
      document.getElementById('cover_video').style.opacity = 0.4;
    }, 4000);

  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
    <div className="cover_video" id="cover_video">
      <video autoPlay="true" id="apt_video" loop="true" playsInline="true" width="100%">
          <source src="https://openmetastability.s3.amazonaws.com/aptr2.mp4" type="video/mp4"/>
      </video>
    </div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
              <div id="head_logos" className="head-logos">
                  <img src="img/apt.svg" alt="" className="apt-logo-png" id="head_apt_logo"/>
                  <img src="img/apt_name.svg" alt="" className="apt-name-png" id="head_apt_name"/>
                  </div>
                  <div id="head_desc" className="head-desk">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1> 
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll learn-more"
                >
                  Learn More
                </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
