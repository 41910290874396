import React from "react";

export const Features = (props) => {
  const { handleFeatur } = props;
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => {
              const src_ = `img/features/${d.icon}`;
              return (
                <div key={`${d.title}-${i}`} id="featur" className="col-xs-6 col-md-4" onClick={() => handleFeatur(d.title)}>
                  {/* <span></span> */}
                  <div className="feater-glow"></div>

                  <div class="imgwrap">
                    <img src={src_} alt="" className="features-png" />
                  </div>

                  <h3 className="features-name">{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              )
            })
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
