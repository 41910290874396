import { useEffect, useState } from "react";
import React from "react";

export const Contact = (props) => {

  const { selectedType } = props;

  const [form, setFromed] = useState(true);

  let tg = {
    token: process.env.REACT_APP_BOT_TOKEN,
    chat_id: process.env.REACT_APP_CHAT_ID
  }

  const fileToDataUri = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  })

  const [dataUri, setDataUri] = useState('');
  const [fileName, setFileName] = useState('filename');

  const onChange = (file) => {
    // console.log('dataUri', dataUri);

    if (!file) {
      setDataUri('');
      return;
    }

    fileToDataUri(file)
      .then(dataUri => {
        setDataUri(dataUri);
        // console.log('[onChange] file', file);
        setFileName(file?.name);
      })
  }

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }


  const sendMessage = (text) => {
    const url = `https://api.telegram.org/bot${tg.token}/sendMessage`;

    const obj = {
      chat_id: tg.chat_id,
      text: text
    };

    const xht = new XMLHttpRequest();
    xht.open("POST", url, true);
    xht.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xht.send(JSON.stringify(obj));
    setFromed(false);

    const blob = dataURItoBlob(dataUri);

    const fileData = new FormData();
    fileData.append('chat_id', tg.chat_id);
    fileData.append('document', blob, fileName);

    const request = new XMLHttpRequest();
    request.open('POST', `https://api.telegram.org/bot${tg.token}/sendDocument`);
    request.send(fileData);
  }

  const handleSubmit = (event) => {
    const ident = new Date().toJSON().slice(0, 10).split('-');
    const n = parseInt(ident[0]) + parseInt(ident[1]) * 10 + parseInt(ident[2]);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let hostname = 'default';
    if (typeof window !== "undefined") {
      hostname = window?.location?.hostname;
      // console.log('window', window);
    }

    const text = `New application №${n}⠀
    [hostname: ${hostname}]

    Name/Company: ${data.get('name')},⠀

    Email/Phone: ${data.get('email')},⠀

    Type: ${data.get('options') ? data.get('options') : '-'}

    Description: "${data.get('message') ? data.get('message') : '-'}"`;
    sendMessage(text, n);

    console.log('text', text);
  };

  const options = ['Flanges', 'Pipe fittings', 'Pipes'];

  useEffect(() => {
    document.getElementById('options').value = selectedType;
    
  }, [selectedType]);
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Send An Offer</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              {form ?
                <form name="sentMessage" validate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-control-select">
                        <select
                          type="options"
                          id="options"
                          name="options"
                          placeholder="Type"
                          required
                          className="form-select"
                          aria-label="Type"
                        >
                          <option selected>Open this select menu</option>
                          {options.map((el, k) =>
                            <option key={k} value={el}>{el}</option>
                          )}
                        </select>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div>
                    <input type="file" id="fileload" onChange={(event) => onChange(event.target.files[0] || null)} />
                    <span style={{ fontSize: '8.5pt' }}>document/archive format only*</span>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form> : <>
                <div className="success-animation">
                  <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                </div><div className="scu-msg">Application has been sent, we will contact you shortly</div></>
              }
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Aptracor LLC
          </p>
        </div>
      </div>
    </div>
  );
};
